import React, { useEffect, useMemo } from 'react';
import ThunkGetCountries from '@store/slices/countries/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { countryCodesListSelector } from '@store/slices/countries';

export const useCountryCodesOptions = () => {
  const { countries, loading } = useSelector(countryCodesListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries?.length) {
      dispatch(ThunkGetCountries());
    }
  }, [countries.length, dispatch]);

  return useMemo(
    () => ({
      loading,
      countries,
      countryCodesOptions: countries.map(
        list => ({
          value: `${list.code} ${list.dialCode}`,
          label: `${list.code} ${list.dialCode}`,
          icon: (
            <img
              alt={list.code}
              src={`https://flagcdn.com/w20/${list.code.toLowerCase()}.png`}
            />
          ),
        }),
        [countries],
      ),
    }),
    [countries, loading],
  );
};

export default {};
