import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import {
  SelectResourceOptions,
  SelectResourceOptionsLoading,
} from '@store/slices/liveChats/selectors';
import {
  ThunkGetResources,
  ThunkLiveChatScript,
} from '@store/slices/liveChats/thunks';

import copyText from '@utils/copy';
import getFormattedDate from '@utils/getFormattedDate';
import { LIVE_CHAT_CONFIGURATIONS } from '@constants/routes';

import { ReactComponent as CopyIcon } from '@assets/icons/copy_blue.svg';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ReusableButton from '@common/Button/Button';
import LiveChatActions from '@components/LiveChat/components/LiveChatActions';
import LiveChatTableItemActions from '@components/LiveChat/components/LiveChatTableItemActions';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';

import useStyles from './styles';

const options = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

const LiveChat = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push } = useHistory();

  const resourcesOptions = useSelector(SelectResourceOptions);
  const resourcesOptionsLoading = useSelector(SelectResourceOptionsLoading);

  const [selectedResource, setSelectedResource] = useState(undefined);

  const copyScript = useCallback(
    id => {
      dispatch(ThunkLiveChatScript(id))
        .unwrap()
        .then(p => {
          copyText(p.data);
        });
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Awaiting Response',
        key: 'isAnswered',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        minWidth: 200,
        width: 200,
        type: TableTypes.dropdown,
        dropDownValues: options,
        filterByAutocomplete: 'isAnswered',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isAnswered} useMultiColor />
        ),
      },
      {
        name: 'Status',
        key: 'isClosed',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        minWidth: 175,
        width: 175,
        type: TableTypes.dropdown,
        dropDownValues: options,
        filterByAutocomplete: 'isClosed',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isClosed} useMultiColor />
        ),
      },
      {
        name: 'Last Message Date',
        key: 'lastMessageDate',
        sortable: true,
        isCustomAccessor: true,
        minWidth: 200,
        width: 200,
        accessor: i => <>{getFormattedDate(i.lastMessageDate)}</>,
      },
      {
        name: 'Last Message',
        key: 'lastMessage',
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'User Identity',
        key: 'userIdentity',
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'User Name',
        key: 'userName',
        minWidth: 150,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Ticket ID',
        sortable: true,
        searchable: true,
        key: 'ticketId',
        minWidth: 150,
        isCustomAccessor: true,
        accessor: item => (
          <div
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              window.open(item.ticketLink, '_blank');
            }}
          >
            {item?.ticketId}
          </div>
        ),
      },
      {
        name: 'Assignee',
        key: 'assigneeName',
        minWidth: 150,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 150,
        width: 150,
        accessor: item => (
          <LiveChatTableItemActions
            {...item}
            selectedResource={selectedResource}
          />
        ),
      },
    ],
    [selectedResource],
  );

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetResources())
      .unwrap()
      .then(resources => {
        if (resources.length === 1) {
          setSelectedResource(resources[0].id);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (resourcesOptionsLoading) return <Loading />;

  return (
    <div className={classes.container}>
      <Table
        isDisplayTable={selectedResource}
        actionsComponent={
          <div className={classes.actionsWrapper}>
            <div className={classes.dropDownWrapper}>
              <div className={classes.dropDown}>
                <DropDown
                  options={resourcesOptions}
                  input={{
                    onChange: e => setSelectedResource(e.target.value),
                    value: +selectedResource,
                  }}
                />
              </div>
              <ReusableButton
                label="Configurations"
                onClick={() => push(LIVE_CHAT_CONFIGURATIONS)}
                size="md"
              />
              {selectedResource && (
                <Tooltip title="Copy script" style={{ cursor: 'pointer' }}>
                  <CopyIcon onClick={() => copyScript(selectedResource)} />
                </Tooltip>
              )}
            </div>
            {selectedResource && <LiveChatActions />}
          </div>
        }
        columns={columns}
        resource={`LiveChatAdmin/resources/${selectedResource}/chats`}
        defaultFilters={{ isClosed: false }}
        defaultSort={{ fieldName: 'lastMessageDate', order: 'DESC' }}
      />
    </div>
  );
};

export default LiveChat;
